import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/Personal-Website/Personal-Website/src/components/layout/MD_Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`AI Bird Watching`}</h1>
    <p>{`My birds need to be watched, but I cannot always watch them. Using the
ImageAI Python library I can do this easily. With ~20 lines of code I can
find if there are any birds in an image, and count how many with varying
degrees of accuracy.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`setup_detector`}</inlineCode>{` function sets up the `}<inlineCode parentName="p">{`ObjectDetection`}</inlineCode>{` model from
`}<a parentName="p" {...{
        "href": "https://github.com/OlafenwaMoses/ImageAI"
      }}>{`ImageAI`}</a>{`.
In this model I choose to load the
`}<a parentName="p" {...{
        "href": "https://github.com/OlafenwaMoses/ImageAI/releases/download/1.0/yolo-tiny.h5"
      }}>{`TinyYoloV3 model`}</a>{`,
chosen because it is the most lightweight model, and I plan to run this on
my Raspberry PI.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`from imageai.Detection import ObjectDetection

def setup_detector():
  """
  Build the detection model
  :return: The detection model
  """
  execution_path = os.getcwd()
  detector = ObjectDetection()
  detector.setModelTypeAsTinyYOLOv3()
  detector.setModelPath(os.path.join(execution_path, "./yolo-tiny.h5"))
  detector.loadModel()
`}</code></pre>
    <p>{`Using this model it is time to detect the birds. I only want to see the
birds in the image do I set the `}<inlineCode parentName="p">{`CustomObjects`}</inlineCode>{` to birds only. Then by
feeding in my input image I get the image seen below as output.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`import os

def process_picture(image_path, output_path):
  """
  Process the input image
  :param image_path: Input image path
  :param output_path: Output image path
  :return: Found objects
  """
  detector = setup_detector()
  custom_object = detector.CustomObjects(bird=True)
  detections = detector.detectObjectsFromImage(custom_objects=custom_object,
                                               input_image=image_path,
                                               output_image_path=output_path,
                                               minimum_percentage_probability=1,
                                               display_object_name=False)

  return detections
`}</code></pre>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "480px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4fdc16c5d58318a2274a9812173b9cad/7cc5e/bird_watching_output.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUCAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABoz/JlLaUTPQ2E//EABwQAAICAgMAAAAAAAAAAAAAAAECABESIgMEE//aAAgBAQABBQL0VI3at15QQ+0xxgIpdkMoT//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EAB0QAAIBBAMAAAAAAAAAAAAAAAABEAIRITEyQaH/2gAIAQEABj8Cs6ujGjc8fRNij//EABwQAQACAgMBAAAAAAAAAAAAAAEAETFhIUFxUf/aAAgBAQABPyGsUKtiKOe0GqD2W364hmTnyEolj6Ykw0hVif/aAAwDAQACAAMAAAAQQ8e//8QAFREBAQAAAAAAAAAAAAAAAAAAESD/2gAIAQMBAT8QY//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBEh/9oACAECAQE/EHZs/wD/xAAdEAEBAAICAwEAAAAAAAAAAAABEQAxIUFRYXGR/9oACAEBAAE/EG2IAA1gAeWdaY/KmouUkWOHx6wkV1rTD6Dyxi4lyWHdmChdEP3Ddn3P/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "ImageAI Output",
            "title": "ImageAI Output",
            "src": "/static/4fdc16c5d58318a2274a9812173b9cad/7cc5e/bird_watching_output.jpg",
            "srcSet": ["/static/4fdc16c5d58318a2274a9812173b9cad/f93b5/bird_watching_output.jpg 300w", "/static/4fdc16c5d58318a2274a9812173b9cad/7cc5e/bird_watching_output.jpg 480w"],
            "sizes": "(max-width: 480px) 100vw, 480px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`With these ~20 lines of code we have found all the birds in our image. As
you can see the probability percentages are quite low, but this can be
improved with a different model. The full guide including all model choices
can be found on the full
`}<a parentName="p" {...{
        "href": "https://github.com/OlafenwaMoses/ImageAI/blob/master/imageai/Detection/README.md"
      }}>{`ImageAI Detection documentation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      